import { PUBLIC_SITE_URL } from '$env/static/public';

// Get base URL from environment variable
export const BASE_URL = PUBLIC_SITE_URL;

// Create a full URL from a path
export const createUrl = (path: string): string => {
    // Remove leading slash if present to avoid double slashes
    const cleanPath = path.startsWith('/') ? path.slice(1) : path;
    return `${BASE_URL}/${cleanPath}`;
}; 